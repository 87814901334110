import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  Option,
} from "antd";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import logo from "../../images/TRA.PNG";
import emailjs from "@emailjs/browser";
import "../../styles/Home.css";
function RequestQuote() {
  const { TextArea } = Input;
  const { Option } = Select;
  const onOpenChange = (status) => {
    if (!status) {
      document.activeElement.blur();
    }
  }
  // ---
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let logoStyle = {};
  if (width < 600) {
    logoStyle = { width: "35%", marginBottom: "1rem" };
  } else {
    logoStyle = { width: "37%", marginBottom: "1rem" };
  }
  // ----
  const [form] = Form.useForm();

  const onFinish = (values) => {
    emailjs
      .send("service_0nkrvwj", "template_50gwrf1", values, "jnRIbgzrjzgsoKdWT")
      .then(
        function (response) {
          if (response.status == 200)
            alert('Finished, Thank you.')
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    console.log("Success:", values);
    onReset();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };
  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <Row justify="center">
        <Link style={{ textAlign: "center" }} to="/">
          {" "}
          <img style={logoStyle} src={logo} alt="TRA Electric Logo" />
        </Link>
      </Row>
      <Row
        className="shadow"
        style={{
          backgroundColor: "lightgray",
          textAlign: "center",
          marginBottom: "2.5em",
          color: "#0B0346",
        }}
      >
        <h1
          style={{
            color: "#0B0346",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: ".5em",
          }}
        >
          {" "}
          Schedule an Appointment
        </h1>
      </Row>

      <Row className="mainDiv">
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&showTitle=0&showPrint=0&src=dmljdG9yQHRyYS1lbGVjdHJpYy5jb20&color=%23039BE5"
            style={{
              border: "0",
              marginLeft: "auto",
              width: "100%",
              height: "60em",
            }}
            scrolling="no"
          ></iframe>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form
            style={{
              marginTop: "1em",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
              padding: "1em",
              textAlign: "center",
            }}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item label="Full Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
            <Form.Item label="Phone Number" name="phone">
              <Input />
            </Form.Item>
            <Form.Item label="Zip Code" name="zip">
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item label="Tenative Start Date" name="start">
              <DatePicker onOpenChange={onOpenChange} style={{ width: "100%" }} />
            </Form.Item>
            {/* <Form.Item label="Tenative End Date" name="end">
              <DatePicker onOpenChange={onOpenChange}  style={{ width: "100%" }} />
            </Form.Item> */}
            <Form.Item label="AM/PM" name="ampm">
              <Select style={{ textAlign: "start" }}>
                <Option value="AM">AM</Option>
                <Option value="PM">PM</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="shadow"
                style={{
                  background: "lightGray",
                  color: "#0B0346",
                  borderRadius: "10px",
                }}
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row

        style={{
          // marginBottom: "5em",
          display: 'flex',
          justifyContent: 'center',
          marginTop: "4em",
          maxWidth: "100%",
          textAlign: "center",
          backgroundColor: "lightgray",
          color: "#0B0346",
        }}
      >
        <Col span={12} style={{ marginTop: 'auto', marginBottom: 'auto', paddingTop: '2rem' }}>
          <p>TRA Electric: <br /> 512-409-8958 </p>
          <p>Licensed and Insured: <br /> TECL-37736</p>
        </Col>
        <Col style={{ margin: 'auto', }}>
          <img className='footerLogo' src={logo} alt="TRA Electric Logo" />
          <p>Se habla español</p>
        </Col>

      </Row>
    </>
  );
}

export default RequestQuote;
